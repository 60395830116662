export default function getWindowValues ( bufferSize, sampleRate, windowFunc, alpha ) {
    let windowValues = new Float32Array( bufferSize );

    var i;
    switch ( windowFunc ) {
        case 'bartlett':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    ( 2 / ( bufferSize - 1 ) ) *
                    ( ( bufferSize - 1 ) / 2 - Math.abs( i - ( bufferSize - 1 ) / 2 ) );
            }
            break;
        case 'bartlettHann':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    0.62 -
                    0.48 * Math.abs( i / ( bufferSize - 1 ) - 0.5 ) -
                    0.38 * Math.cos( ( Math.PI * 2 * i ) / ( bufferSize - 1 ) );
            }
            break;
        case 'blackman':
            alpha = alpha || 0.16;
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    ( 1 - alpha ) / 2 -
                    0.5 * Math.cos( ( Math.PI * 2 * i ) / ( bufferSize - 1 ) ) +
                    ( alpha / 2 ) *
                    Math.cos( ( 4 * Math.PI * i ) / ( bufferSize - 1 ) );
            }
            break;
        case 'cosine':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] = Math.cos(
                    ( Math.PI * i ) / ( bufferSize - 1 ) - Math.PI / 2
                );
            }
            break;
        case 'gauss':
            alpha = alpha || 0.25;
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] = Math.pow(
                    Math.E,
                    -0.5 *
                    Math.pow(
                        ( i - ( bufferSize - 1 ) / 2 ) /
                        ( ( alpha * ( bufferSize - 1 ) ) / 2 ),
                        2
                    )
                );
            }
            break;
        case 'hamming':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    0.54 -
                    0.46 * Math.cos( ( Math.PI * 2 * i ) / ( bufferSize - 1 ) );
            }
            break;
        case 'hann':
        case undefined:
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    0.5 * ( 1 - Math.cos( ( Math.PI * 2 * i ) / ( bufferSize - 1 ) ) );
            }
            break;
        case 'lanczoz':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    Math.sin( Math.PI * ( ( 2 * i ) / ( bufferSize - 1 ) - 1 ) ) /
                    ( Math.PI * ( ( 2 * i ) / ( bufferSize - 1 ) - 1 ) );
            }
            break;
        case 'rectangular':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] = 1;
            }
            break;
        case 'triangular':
            for ( i = 0; i < bufferSize; i++ ) {
                windowValues[ i ] =
                    ( 2 / bufferSize ) *
                    ( bufferSize / 2 - Math.abs( i - ( bufferSize - 1 ) / 2 ) );
            }
            break;
        default:
            throw Error( "No such window function '" + windowFunc + "'" );
    }

	return windowValues
}