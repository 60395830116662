/* eslint-enable complexity, no-redeclare, no-var, one-var */
import getWindowValues from '../../util/window.js';

// minFrq and maxFrq are range of calculate
export default function calculateFZeros ( fs, buffer, cycle, windowFunc, alpha, minFrq, maxFrq, channelIndex, dataLevel ) {
    if( !( fs && buffer && cycle && windowFunc && alpha && minFrq && maxFrq ) ) return
    if ( minFrq == 0 ) minFrq = 1
    if ( maxFrq == 0 || maxFrq < minFrq ) return

    if ( !fs ) return
    const pointStart = ~~( fs / maxFrq )
    const pointEnd = ~~( fs / minFrq )
    const windowWidth = pointEnd * 2;

    if ( cycle > windowWidth ) return
    const channelData = buffer.getChannelData( channelIndex )
    if ( !channelData ) return
    
    let [segments, minV, dynamicRange] = createSegmentList( channelData, windowWidth, cycle )
    if( windowFunc != 'rectangular') {
        const windowValues = getWindowValues( windowWidth, fs, windowFunc, alpha )
        segments = windowing( segments, windowValues )
    }
    const result = []
    segments.map( (array, i) => {
        if (skipData(array, dataLevel, minV, dynamicRange)) return
        let [frq, maxCoef] = autoCorrelate( array, windowWidth, cycle, fs, pointStart, pointEnd, maxFrq )
        let xSmp = ~~( cycle / 2 + cycle * i )
        result.push( { frequency: frq, corrcoef: maxCoef, xSmp: xSmp } )
    })
    return result
}

function skipData(array, dataLevel, minV, dynamicRange){
    let total = array.reduce( (sum, v) => {
        return sum + v * v;
    }, 0)
    if( total == 0 ) return true
    const value = total / array.length
    const avrDb = 10 * Math.log10( value )
    return dynamicRange - avrDb < dataLevel
}

function autoCorrelate ( array, windowWidth, cycle, fs, pointStart, pointEnd, maxFrq ) {
    const magic = 8 * Math.exp( -5 )
    let coef, power
    [ coef, power ] = corrcoef( windowWidth, array, array, true )
    let frq = 0
    let maxCoef = -10
    for ( let lag = pointStart; lag < pointEnd; lag++ ) {
        [ coef, power ] = corrcoef( windowWidth - lag, array, array.slice( lag, ), true )

        coef += magic * 32 / lag

        if ( coef > maxCoef ) {
            maxCoef = coef
            frq = fs / lag
        }
    }
    return [ frq, maxCoef ]
}

function corrcoef ( n, x, y, flag ) {
    let i, sx, sy, sxx, syy, sxy, power, coef;

    sx = sy = sxx = syy = sxy = 0;
    for ( i = 0; i < n; i++ ) {
        sx += x[ i ];
        sy += y[ i ];
        sxx += x[ i ] * x[ i ];
        syy += y[ i ] * y[ i ];
        sxy += x[ i ] * y[ i ];
    }
    sx /= n;
    sxx = ( sxx - n * sx * sx ) / ( n - 1 );
    sy /= n;
    syy = ( syy - n * sy * sy ) / ( n - 1 );
    sxx = ( sxx > 0 ? Math.sqrt( sxx ) : 0 );
    syy = ( syy > 0 ? Math.sqrt( syy ) : 0 );

    if ( flag )
        power = 10 * Math.log10( sxy / n );
    else
        power = 0;

    if ( sxx * syy == 0 ) return [ 0, power ]
    else coef = ( sxy - n * sx * sy ) / ( ( n - 1 ) * sxx * syy )

    return [ coef, power ]
}

function createSegmentList ( channelData, windowWidth, cycle ) {
    let currentOffset = 0
    let result = []
    let currentMinV = Number.MAX_SAFE_INTEGER
    let currentMaxV = Number.MIN_SAFE_INTEGER
    while ( currentOffset + windowWidth < channelData.length ) {
        let samples = channelData.slice(
            currentOffset,
            currentOffset + windowWidth
        )
        const getMinV = function (a, b) {
            if( !a && b ) return Math.abs(b)
            else if ( a && !b) return Math.abs(a)
            else if( a && b) return Math.min(Math.abs(a), Math.abs(b))
        }
        const getMaxV = function (a, b) {
            if( !a && b ) return Math.abs(b)
            else if ( a && !b) return Math.abs(a)
            else if( a && b) return Math.max(Math.abs(a), Math.abs(b))
        }

        let minV = samples.reduce(getMinV)
        if( minV < currentMaxV ) currentMinV = minV

        let maxV = samples.reduce(getMaxV)
        if( maxV > currentMaxV ) currentMaxV = maxV

        result.push( samples )
        currentOffset += cycle
    }
    const dynamicRange = 20 * Math.log10(currentMaxV / currentMinV)
    return [result, currentMinV, dynamicRange]
}


function windowing ( segmentList, windowValues ) {
    let result = []
    for ( let seg = 0; seg < segmentList.length; seg++ ) { // for each channel
        const segmentSize = segmentList[ seg ].length
        if ( segmentSize == 0 ) {
            result.push( [] )
        } else {
            if ( segmentSize != windowValues.length ) {
                console.error( 'fftSamples != windowValues.length' );
                return
            }
            const array = new Array( segmentSize )
            for ( let i = 0; i < segmentSize; i++ ) {
                array[ i ] = segmentList[ seg ][ i ] * windowValues[ i ]
            }
            result.push( array )
        }
    }
    return result
}
