/* eslint-enable complexity, no-redeclare, no-var, one-var */
import calculateFzeros from './coef.js';
import FFT from '../../util/fft.js';

/**
 * @typedef {Object} fzeroPluginParams
 * @property {string|HTMLElement} container Selector of element or element in
 * which to render
 * @property {number} fftSamples=512 Number of samples to fetch to FFT. Must be
 * a power of 2.
 * @property {boolean} splitChannels=false Render with separate fzeros for
 * the channels of the audio
 * @property {number} height=fftSamples/2 Height of the fzero view in CSS
 * pixels
 * @property {boolean} labels Set to true to display frequency labels.
 * @property {number} noverlap Size of the overlapping window. Must be <
 * fftSamples. Auto deduced from canvas size by default.
 * @property {string} windowFunc='hann' The window function to be used. One of
 * these: `'bartlett'`, `'bartlettHann'`, `'blackman'`, `'cosine'`, `'gauss'`,
 * `'hamming'`, `'hann'`, `'lanczoz'`, `'rectangular'`, `'triangular'`
 * @property {?number} alpha Some window functions have this extra value.
 * (Between 0 and 1)
 * @property {number} pixelRatio=wavesurfer.params.pixelRatio to control the
 * size of the fzero in relation with its canvas. 1 = Draw on the whole
 * canvas. 2 = Draw on a quarter (1/2 the length and 1/2 the width)
 * @property {number} frequencyMin=100 Min frequency to scale fzero.
 * @property {number} frequencyMax=500 Max frequency to scale fzero.
 * Set this to samplerate/2 to draw whole range of fzero.
 * @property {?boolean} cutPower=50 Filter out low-level noise by lowering magnitude by cutPower.
 * cutPower converts between 0 and 255
 * @property {?boolean} deferInit Set to true to manually call
 * `initPlugin('fzero')`
 * @property {number} pixelPerSmp=6 Adjust plot spacing of fzero
 * @property {number} uvThreshold=0.7 Treshold to distinguish between vioce and unvoice
 */

export default class FzeroPlugin {
    /**
     * Fzero plugin definition factory
     *
     * This function must be used to create a plugin definition which can be
     * used by wavesurfer to correctly instantiate the plugin.
     *
     * @param  {FzeroPluginParams} params Parameters used to initialise the plugin
     * @return {PluginDefinition} An object representing the plugin.
     */
    static create(params) {
        return {
            name: params.name || 'fzero',
            deferInit: params && params.deferInit ? params.deferInit : false,
            params: params,
            staticProps: {
                FFT: FFT
            },
            instance: FzeroPlugin
        };
    }

    constructor(params, ws) {
        this.params = params;
        this.wavesurfer = ws;
        this.util = ws.util;

        this.frequenciesDataUrl = params.frequenciesDataUrl;
        this._onScroll = e => {
            this.updateScroll(e);
        };
        this._onRender = () => {
            this.render();
        };
        this._onWrapperClick = e => {
            this._wrapperClickHandler(e);
        };
        this._onReady = () => {
            const drawer = (this.drawer = ws.drawer);

            this.container =
                'string' == typeof params.container
                    ? document.querySelector(params.container)
                    : params.container;

            if (!this.container) {
                throw Error('No container for WaveSurfer spectrogram');
            }
            if (params.colorMap) {
                if (params.colorMap.length < 256) {
                    throw new Error('Colormap must contain 256 elements');
                }
                for (let i = 0; i < params.colorMap.length; i++) {
                    const cmEntry = params.colorMap[i];
                    if (cmEntry.length !== 4) {
                        throw new Error(
                            'ColorMap entries must contain 4 values'
                        );
                    }
                }
                this.colorMap = params.colorMap;
            } else {
                this.colorMap = [];
                for (let i = 0; i < 256; i++) {
                    const val = (255 - i) / 256;
                    this.colorMap.push([val, val, val, 1]);
                }
            }
            this.width = drawer.width;
            this.pixelRatio = this.params.pixelRatio || ws.params.pixelRatio;
            this.fftSamples =
                this.params.fftSamples || ws.params.fftSamples || 512;
            this.height = this.params.height || this.fftSamples / 2;
            this.noverlap = params.noverlap;
            this.windowFunc = params.windowFunc;
            this.alpha = params.alpha;
            this.splitChannels = params.splitChannels;
            this.channels = this.splitChannels ? ws.backend.buffer.numberOfChannels : 1;

            // Getting file's original samplerate is difficult(#1248).
            // So set 12kHz default to render like wavesurfer.js 5.x.
            this.frequencyMin = params.frequencyMin || 0;
            this.frequencyMax = params.frequencyMax || 12000;
            this.cutPower = params.cutPower || 50;
            if (this.cutPower < 0) this.cutPower = 0;
            else if (this.cutPower > 255) this.cutPower = 255;
            this.cycle = params.cycle || 1000
            this.dataLevel = params.dataLevel || 50
            this.showUnVoiceFzero = params.showUnVoiceFzero || false

            this.createWrapper();
            this.createCanvas();
            // this.render();

            drawer.wrapper.addEventListener('scroll', this._onScroll);
            ws.on('redraw', this._onRender);
        };
        this.pixelPerSmp = params.pixelPerSmp || 6
        this.tooltipName = 'container__tooltip--fzero'
        this.svgName = 'container__svg--fzero'
        this.fzeros = new Array(6).fill(null)
    }

    createWrapper() {
        const prevFzero = this.container.querySelector(this.params.name);
        if (prevFzero) {
            this.container.removeChild(prevFzero);
        }
        const wsParams = this.wavesurfer.params;
        this.wrapper = document.createElement(this.params.name);
        // if labels are active
        if (this.params.labels) {
            const labelsEl = (this.labelsEl = document.createElement('canvas'));
            labelsEl.classList.add('spec-labels');
            this.drawer.style(labelsEl, {
                position: 'absolute',
                zIndex: 9,
                height: `100%`,
                width: `55px`
            });
            this.wrapper.appendChild(labelsEl);
            this.loadLabels(
                'rgba(68,68,68,0.5)',
                '12px',
                '10px',
                '',
                '#fff',
                '#f7f7f7',
                'center',
                '#specLabels'
            );
        }

        this.drawer.style(this.wrapper, {
            display: 'block',
            position: 'relative',
            userSelect: 'none',
            webkitUserSelect: 'none',
            height: `100%`
        });

        if (wsParams.fillParent || wsParams.scrollParent) {
            this.drawer.style(this.wrapper, {
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'hidden'
            });
        }
        this.container.appendChild(this.wrapper);

        this.wrapper.addEventListener('click', this._onWrapperClick);
    }

    render( channelIndex, height ) {
        this.updateCanvasStyle()
        this.removeSvgAndTips()
        if(channelIndex == null) return
        if (this.frequenciesDataUrl) {
            this.loadFrequenciesData(this.frequenciesDataUrl);
        } else {
            this.getFrequencies(this.drawSpectrogram, channelIndex);
        }
        this.getFzeros(this.drawFzero, channelIndex, height)
    }

    removeSvgAndTips(){
        const tooltips = document.getElementsByClassName( this.tooltipName )
        tooltips && [ ...tooltips ].forEach( el => el.remove() )
        const svgs = document.getElementsByClassName( this.svgName )
        svgs && [ ...svgs ].forEach( el => el.remove() )
    }

    clearCalculationCache(){
        this.fzeros = new Array(6).fill(null)
        return true
    }

    // fzeros[seg] = { frequency: frq, corrcoef: maxCoef, power: power, xSmp: xSmp }
    drawFzero ( fzeroData, height, my ) {
        if( !fzeroData ) return

        const width = my.width;
        const freqMin = my.frequencyMin;
        const freqMax = my.frequencyMax;

        if ( !my.wrapper ) {
            return;
        }

        // let target = document.getElementById('demo2');
        const svg = document.createElementNS( 'http://www.w3.org/2000/svg', 'svg' );
        svg.classList.add( my.svgName );
        svg.setAttribute( 'viewBox', `0 0 ${ width } ${ height }` );
        svg.setAttribute( 'width', width );
        svg.setAttribute( 'height', height );
        svg.setAttribute( 'style', 'position: absolute; z-index: 5' );
        my.wrapper.appendChild( svg );

        const tooltip = document.createElement( 'div' )
        tooltip.classList.add( my.tooltipName );
        const tooltipWidth = 100
        const tooltipHeight = 45
        Object.assign( tooltip.style, {
            display: 'none',
            position: 'absolute',
            width: `${ tooltipWidth }px`,
            height: `${ tooltipHeight }px`,
            borderRadius: '10px',
            padding: '5px',
            zIndex: 6,
            backgroundColor: '#cccccc',
            opacity: '0.6',
            color: 'black',
            verticalAlign: 'middle',
        } )
        my.wrapper.prepend( tooltip )

        fzeroData.map(v => {
            const circle = document.createElementNS( 'http://www.w3.org/2000/svg', 'circle' );
            // plot formant
            const x = ~~(v.xSmp / my.buffer.length * width * 1000) / 1000
            const y = ~~((freqMax - v.frequency) / (freqMax - freqMin) * height * 1000) / 1000
            const time = ~~(v.xSmp / my.buffer.length * my.buffer.duration * 1000) / 1000
            
            circle.setAttributeNS( null, 'cx', x )
            circle.setAttributeNS( null, 'cy', y )
            circle.setAttributeNS( null, 'r', 3 )

            circle.setAttributeNS( null, 'fill', 'red' )
            // if( v.maxCoef > my.uvThreshold ) {
            //     circle.setAttributeNS( null, 'fill', 'red' )
            // } else if (my.showUnVoiceFzero) {
            //     circle.setAttributeNS( null, 'fill', 'blue' )
            // }

            // pop toolchip event
            circle.addEventListener( 'mouseover', e => {
                // not to pop toolchip outside parent div
                tooltip.style.left = x - tooltipWidth < 0 ? ~~x + 'px' : ~~(x - tooltipWidth) + 'px'
                tooltip.style.top = y - tooltipHeight < 0 ? ~~y + 'px' : ~~(y - tooltipHeight) + 'px'
                tooltip.innerHTML = `time: ${time}[s]<br>F0: ${~~(v.frequency)}[Hz]`
                tooltip.style.display = 'block'
            } )
            // hide tooltip event
            svg.addEventListener( 'mouseout', () => {
                tooltip.style.display = 'none'
            } )
            svg.appendChild( circle );
        } )
    }

    getFzeros(callback, channelIndex, height) {
        const buffer = (this.buffer = this.wavesurfer.backend.buffer);
        if ( !buffer ) {
            this.fireEvent( 'error', 'Web Audio buffer is not available' );
            return;
        }

        const fs = this.wavesurfer.backend.buffer.sampleRate
        let fzeros
        if( this.fzeros[channelIndex] ) fzeros = this.fzeros[channelIndex]
        else {
            fzeros = calculateFzeros( fs, buffer, this.cycle, this.windowFunc, this.alpha, this.frequencyMin, this.frequencyMax, channelIndex, this.dataLevel )
            this.fzeros[channelIndex] = fzeros
        }
        callback( fzeros, height, this );
    }

    // The following code will be extended
    init() {
        // Check if wavesurfer is ready
        if (this.wavesurfer.isReady) {
            this._onReady();
        } else {
            this.wavesurfer.once('ready', this._onReady);
        }
    }

    destroy() {
        this.unAll();
        this.wavesurfer.un('ready', this._onReady);
        this.wavesurfer.un('redraw', this._onRender);
        this.drawer && this.drawer.wrapper.removeEventListener('scroll', this._onScroll);
        this.wavesurfer = null;
        this.util = null;
        this.params = null;
        if (this.wrapper) {
            this.wrapper.removeEventListener('click', this._onWrapperClick);
            this.wrapper.parentNode.removeChild(this.wrapper);
            this.wrapper = null;
        }
    }

    _wrapperClickHandler(event) {
        event.preventDefault();
        const relX = 'offsetX' in event ? event.offsetX : event.layerX;
        this.fireEvent('click', relX / this.width || 0);
    }

    createCanvas() {
        const canvas = (this.canvas = this.wrapper.appendChild(
            document.createElement('canvas')
        ));

        this.spectrCc = canvas.getContext('2d');

        this.util.style(canvas, {
            position: 'absolute',
            zIndex: 4
        });
    }

    updateCanvasStyle() {
        const width = Math.round(this.width / this.pixelRatio) + 'px';
        this.canvas.width = this.width;
        this.canvas.height = this.fftSamples / 2 * this.channels;
        this.canvas.style.width = width;
        this.canvas.style.height = '100%';
    }

    drawSpectrogram(frequenciesData, my, maxValue) {
        if (!isNaN(frequenciesData[0][0])) { // data is 1ch [frame, freq] format
            // to [channel, frame, freq] format
            frequenciesData = [frequenciesData];
        }

        const spectrCc = my.spectrCc;
        const height = my.fftSamples / 2;
        const width = my.width;
        const freqFrom = my.buffer.sampleRate / 2;
        const freqMin = my.frequencyMin;
        const freqMax = my.frequencyMax;

        if (!spectrCc) {
            return;
        }

        for (let c = 0; c < frequenciesData.length; c++) { // for each channel
            const originalWidth = frequenciesData[c].length
            const originalHeight = frequenciesData[c][0].length
            const imageData = new ImageData(originalWidth, originalHeight);

            for (let i = 0; i < originalWidth; i++) {
                for (let j = 0; j < originalHeight; j++) {
                    let value = frequenciesData[c][i][j]
                    const cutPower = my.cutPower
                    if (cutPower != 255) {
                        if (value <= my.cutPower) {
                            value = 0
                        } else {
                            value = ~~((value - cutPower) / (maxValue - cutPower) * 255)
                        }
                    }
                    const colorMap = my.colorMap[value];
                    const redIndex = ((originalHeight - j) * originalWidth + i) * 4;
                    imageData.data[redIndex] = colorMap[0] * 255;
                    imageData.data[redIndex + 1] = colorMap[1] * 255;
                    imageData.data[redIndex + 2] = colorMap[2] * 255;
                    imageData.data[redIndex + 3] = colorMap[3] * 255;
                }
            }

            // scale and stack spectrograms
            createImageBitmap(imageData).then(renderer =>
                spectrCc.drawImage(renderer,
                    0, originalHeight * (1 - freqMax / freqFrom), // source x, y
                    originalWidth, height * (freqMax - freqMin) / freqFrom, // source width, height
                    0, height * c, // destination x, y
                    width, height // destination width, height
                )
            );
        }
    }

    getFrequencies(callback, channelIndex) {
        const fftSamples = this.fftSamples;
        const buffer = (this.buffer = this.wavesurfer.backend.buffer);
        const channels = this.channels;

        if (!buffer) {
            this.fireEvent('error', 'Web Audio buffer is not available');
            return;
        }

        // This may differ from file samplerate. Browser resamples audio.
        const sampleRate = buffer.sampleRate;

        let noverlap = this.noverlap;
        if (!noverlap) {
            const uniqueSamplesPerPx = buffer.length / this.canvas.width;
            noverlap = Math.max(0, Math.round(fftSamples - uniqueSamplesPerPx));
        }

        const fft = new FFT(
            fftSamples,
            sampleRate,
            this.windowFunc,
            this.alpha
        );

        let maxValue = -Number.MAX_VALUE
        const channelData = buffer.getChannelData(channelIndex);
        const channelFreq = [];
        let currentOffset = 0;

        while (currentOffset + fftSamples < channelData.length) {
            const segment = channelData.slice(
                currentOffset,
                currentOffset + fftSamples
            );
            const spectrum = fft.calculateSpectrum(segment);
            const array = new Uint8Array(fftSamples / 2);
            let j;
            for (j = 0; j < fftSamples / 2; j++) {
                array[j] = Math.max(-255, Math.log10(spectrum[j]) * 45);
                if(array[j] > maxValue) maxValue = array[j]
            }
            channelFreq.push(array);
            // channelFreq: [sample, freq]

            currentOffset += fftSamples - noverlap;
        }
        callback(channelFreq, this, maxValue);
    }

    loadFrequenciesData(url) {
        const request = this.util.fetchFile({ url: url });

        request.on('success', data =>
            this.drawSpectrogram(JSON.parse(data), this)
        );
        request.on('error', e => this.fireEvent('error', e));

        return request;
    }

    freqType(freq) {
        return freq >= 1000 ? (freq / 1000).toFixed(1) : Math.round(freq);
    }

    unitType(freq) {
        return freq >= 1000 ? 'KHz' : 'Hz';
    }

    loadLabels(
        bgFill,
        fontSizeFreq,
        fontSizeUnit,
        fontType,
        textColorFreq,
        textColorUnit,
        textAlign,
        container
    ) {
        const frequenciesHeight = this.height;
        bgFill = bgFill || 'rgba(68,68,68,0)';
        fontSizeFreq = fontSizeFreq || '12px';
        fontSizeUnit = fontSizeUnit || '10px';
        fontType = fontType || 'Helvetica';
        textColorFreq = textColorFreq || '#fff';
        textColorUnit = textColorUnit || '#fff';
        textAlign = textAlign || 'center';
        container = container || '#specLabels';
        const bgWidth = 55;
        const getMaxY = frequenciesHeight || 512;
        const labelIndex = 5 * (getMaxY / 256);
        const freqStart = this.frequencyMin;
        const step = (this.frequencyMax - freqStart) / labelIndex;

        // prepare canvas element for labels
        const ctx = this.labelsEl.getContext('2d');
        const dispScale = window.devicePixelRatio;
        this.labelsEl.height = this.height * this.channels * dispScale;
        this.labelsEl.width = bgWidth * dispScale;
        ctx.scale(dispScale, dispScale);

        if (!ctx) {
            return;
        }

        for (let c = 0; c < this.channels; c++) { // for each channel
            // fill background
            ctx.fillStyle = bgFill;
            ctx.fillRect(0, c * getMaxY, bgWidth, (1 + c) * getMaxY);
            ctx.fill();
            let i;

            // render labels
            for (i = 0; i <= labelIndex; i++) {
                ctx.textAlign = textAlign;
                ctx.textBaseline = 'middle';

                const freq = freqStart + step * i;
                const label = this.freqType(freq);
                const units = this.unitType(freq);
                const yLabelOffset = 2;
                const x = 16;
                let y;

                if (i == 0) {
                    y = (1 + c) * getMaxY + i - 10;
                    // unit label
                    ctx.fillStyle = textColorUnit;
                    ctx.font = fontSizeUnit + ' ' + fontType;
                    ctx.fillText(units, x + 24, y);
                    // freq label
                    ctx.fillStyle = textColorFreq;
                    ctx.font = fontSizeFreq + ' ' + fontType;
                    ctx.fillText(label, x, y);
                } else {
                    y = (1 + c) * getMaxY - i * 50 + yLabelOffset;
                    // unit label
                    ctx.fillStyle = textColorUnit;
                    ctx.font = fontSizeUnit + ' ' + fontType;
                    ctx.fillText(units, x + 24, y);
                    // freq label
                    ctx.fillStyle = textColorFreq;
                    ctx.font = fontSizeFreq + ' ' + fontType;
                    ctx.fillText(label, x, y);
                }
            }
        }
    }

    updateScroll(e) {
        if (this.wrapper) {
            this.wrapper.scrollLeft = e.target.scrollLeft;
        }
    }

    resample(oldMatrix) {
        const columnsNumber = this.width;
        const newMatrix = [];

        const oldPiece = 1 / oldMatrix.length;
        const newPiece = 1 / columnsNumber;
        let i;

        for (i = 0; i < columnsNumber; i++) {
            const column = new Array(oldMatrix[0].length);
            let j;

            for (j = 0; j < oldMatrix.length; j++) {
                const oldStart = j * oldPiece;
                const oldEnd = oldStart + oldPiece;
                const newStart = i * newPiece;
                const newEnd = newStart + newPiece;

                const overlap =
                    oldEnd <= newStart || newEnd <= oldStart
                        ? 0
                        : Math.min(
                            Math.max(oldEnd, newStart),
                            Math.max(newEnd, oldStart)
                        ) -
                        Math.max(
                            Math.min(oldEnd, newStart),
                            Math.min(newEnd, oldStart)
                        );
                let k;
                /* eslint-disable max-depth */
                if (overlap > 0) {
                    for (k = 0; k < oldMatrix[0].length; k++) {
                        if (column[k] == null) {
                            column[k] = 0;
                        }
                        column[k] += (overlap / newPiece) * oldMatrix[j][k];
                    }
                }
                /* eslint-enable max-depth */
            }

            const intColumn = new Uint8Array(oldMatrix[0].length);
            let m;
            
            if( this.cutPower != 255) {
                for (m = 0; m < oldMatrix[0].length; m++) {
                    if (column[m] <= this.cutPower) {
                        column[m] = 0
                    } else {
                        column[m] = (column[m] - this.cutPower) / (255 - this.cutPower) * 255
                    }
                    intColumn[m] = column[m];
                }
            }

            newMatrix.push(intColumn);
        }

        return newMatrix;
    }
}




